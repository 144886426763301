
import {Component, Vue} from 'vue-property-decorator';
import CustomPageTitle from "../components/CustomPageTitle.vue";
import MicroSignerComponent from "../components/MicroSignerComponent.vue";
import {MicroSignerControllerApi, TakeoverData} from "../client";
import {FileDownloadService} from "../service/file-download-service";
import http from "../plugins/http";
import TakeoverService from "../service/takeover-service";

@Component({
    components: {
        CustomPageTitle,
        MicroSignerComponent
    }
})
export default class InjunctionTakeover extends Vue {
    private takeoverService = new TakeoverService();
    private microSignerControllerApi = new MicroSignerControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);
    private fileDownloadService = new FileDownloadService();

    private divisionList = ['Cg', 'Cgt', 'Cgf', 'Kt'];
    private email = '';
    private division = 'Cg';
    private caseNumber = '';
    private documentNumber = '';
    private takeoverId = '';
    private companyRegNumberMask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

    private emailErrorMessage = '';
    private companyRegNumberErrorMessage = '';
    private caseIdNumberErrorMessage = '';
    private caseNumberErrorMessage = '';
    private documentNumberErrorMessage = '';
    private takeoverIdErrorMessage = '';

    private downloadFailed = false;
    private downloadFailedKey = '';

    mounted() {
        let changed = false
        const emailQuery = this.$route.query.email;
        if (typeof emailQuery === 'string') {
            this.email = emailQuery;
            changed = true
        }

        const divisionQuery = this.$route.query.ugyszak;
        if (typeof divisionQuery === 'string') {
            this.division = divisionQuery;
            changed = true
        }

        const companyRegNumberQuery = this.$route.query.cegjegyzekszam;
        if (typeof companyRegNumberQuery === 'string') {
            this.caseNumber = companyRegNumberQuery;
            changed = true
        }

        const documentNumberQuery = this.$route.query.iratszam;
        if (typeof documentNumberQuery === 'string') {
            this.documentNumber = documentNumberQuery;
            changed = true
        }

        const takeoverIdQuery = this.$route.query.atveteli_azonosito;
        if (typeof takeoverIdQuery === 'string') {
            this.takeoverId = takeoverIdQuery;
            changed = true
        }
        if (changed) {
            this.frontendValidation();
        }
    }

    private setDownloadFailedToDefault() {
        this.downloadFailed = false;
        this.downloadFailedKey = '';
    }

    private injunctionTakeover() {
        const frontendIsValid = this.frontendValidation();
        if (frontendIsValid) {
            this.signAcknowledgement();
        }
    }

    private frontendValidation(): boolean {
        const isValidEmail = this.validateEmail();
        const isValidCaseNumber = this.validateCaseNumberField();
        const isValidDocumentNumber = this.validateDocumentNumber();
        const isValidTakeoverId = this.validateTakeoverId();
        return isValidEmail && isValidCaseNumber && isValidDocumentNumber && isValidTakeoverId;
    }

    private validateEmail(): boolean {
        const isValid = this.takeoverService.checkEmail(this.email);
        if (isValid) {
            this.emailErrorMessage = '';
        } else {
            this.emailErrorMessage = this.$t('injunction-takeover.error.email').toString();
        }
        return isValid;
    }

    private validateCaseNumberField() {
        if (this.division === 'Cg') {
            return this.validateCompanyRegNumber();
        } else if (this.division === 'Cgf') {
            return this.validateCaseId();
        } else {
            return this.validateCaseNumber();
        }
    }

    private validateCompanyRegNumber() {
        const isValid = this.takeoverService.checkCompanyRegistrationNumber(this.caseNumber);
        if (isValid) {
            this.companyRegNumberErrorMessage = '';
        } else {
            this.companyRegNumberErrorMessage = this.$t('injunction-takeover.error.company-reg-number').toString();
        }
        return isValid;
    }

    private validateCaseId() {
        const isValid = this.takeoverService.checkCaseId(this.caseNumber);
        if (isValid) {
            this.caseIdNumberErrorMessage = '';
        } else {
            this.caseIdNumberErrorMessage = this.$t('injunction-takeover.error.case-id').toString();
        }
        return isValid;
    }

    private validateCaseNumber() {
        const isValid = this.takeoverService.checkCaseNumber(this.caseNumber);
        if (isValid) {
            this.caseNumberErrorMessage = '';
        } else {
            this.caseNumberErrorMessage = this.$t('injunction-takeover.error.case-number').toString();
        }
        return isValid;
    }

    private validateDocumentNumber() {
        const isValid = !isNaN(parseInt(this.documentNumber, 10));
        if (isValid) {
            this.documentNumberErrorMessage = '';
        } else {
            this.documentNumberErrorMessage = this.$t('injunction-takeover.error.document-number').toString();
        }
        return isValid;
    }

    private validateTakeoverId() {
        const length = this.takeoverId.length;
        const isValid = length > 0 && length % 2 == 0 && this.takeoverService.checkHexString(this.takeoverId);
        if (isValid) {
            this.takeoverIdErrorMessage = '';
        } else {
            this.takeoverIdErrorMessage = this.$t('injunction-takeover.error.takeover-id').toString();
        }
        return isValid;
    }

    private signAcknowledgement() {
        this.microSignerControllerApi.startSigningSessionForTakeover(this.getTakeoverData()).then(
            esignSessionRequest => MicroSignerComponent.methods.sign(esignSessionRequest),
        ).catch(
            error => this.handleTakeoverFailed(error)
        );
    }

    private getInjunction(sessionId: string) {
        this.microSignerControllerApi.download(sessionId).then(value => this.fileDownloadService.downloadFilesZipped(value.data, `cegeljarasportal-vegzesatvetel`));
    }

    private getTakeoverData(): TakeoverData {
        return {
            email: this.email,
            division: this.division,
            caseNumber: this.caseNumber,
            documentNumber: parseInt(this.documentNumber, 10),
            takeoverId: this.takeoverId
        }
    }

    private handleTakeoverFailed(error: any) {
        if (error.response) {
            const messageKey = error.response.data.errors[0].messageKey;
            if (messageKey) {
                this.downloadFailed = true;
                this.downloadFailedKey = messageKey;
            }
        }
    }
}

