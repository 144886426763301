import CgtFormService from "./cgt-form-service";

export default class TakeoverService {
    public static readonly REGEXES = {
        hexCode: /^[0-9a-fA-F]+$/,
        caseId: /^0[1-6]-\d{2}-\d{6}$/,
        caseNumber: /^(0[1-9]|1[0-9]|20)-\d{2}-\d{6}$/,
        companyRegNumber: /^(0[1-9]|1[0-9]|20)-(0[0-9]|1[0-9]|2[0-3])-\d{6}$/,
    }

    public checkEmail(email: string): boolean {
        return CgtFormService.REGEXES.email.test(email);
    }

    public checkCompanyRegistrationNumber(companyRegistrationNumber: string) {
        return TakeoverService.REGEXES.companyRegNumber.test(companyRegistrationNumber);
    }

    public checkCaseNumber(companyRegistrationNumber: string) {
        return TakeoverService.REGEXES.caseNumber.test(companyRegistrationNumber);
    }

    public checkCaseId(caseId: string): boolean {
        return TakeoverService.REGEXES.caseId.test(caseId);
    }

    public checkHexString(hexString: string) {
        return TakeoverService.REGEXES.hexCode.test(hexString);
    }
}